.d-subtitle-controls {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .d-video-button {
    height: 38px;
    margin-right: 20px;
  }
}

.pub-page__layout {
  padding: 43px!important;
}
.highlighted-bottom {
  z-index: 900;
  position: relative;
}
.highlighted-top {
  z-index: 1000;
  position: relative;
}
.RichTextEditor__root___2QXK- {
  font-family: inherit !important;
}
.react-datetime-picker__wrapper {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 38px;
  padding: 0 10px;
}
.d-subtitles-language-select {
  min-width: 200px;
  margin-right: 20px;
}
.d-language-subtitles-form {
  padding: 15px 0;
  .divider {
    margin-top: 0;
  }
}
.d-subtitles-form-cell {
  .form-group {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
.d-add-subtitles-button {
  margin-bottom: 15px;
}
.d-subtitles-language-toggle {
  width: 100%;
  border-radius: 4px;
  padding: 7px 15px;
  text-align: left;
  outline: none !important;
  &:focus {
    border-color: #6cdbfe;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
.d-subtitles-language-toggle-wrapper {
  margin-bottom: 15px;
}
.dropzone-wrapper.disabled {
  background: #e9ecef;
  .list-group-item {
    color: #495057;
  }
}

.d-upload-form-group {
  display: flex;
  button {
    margin-left: 10px;
  }
}

.rst__moveHandle.d-last-open-doc {
  background: #f7ae00 !important;
  border-color: #f7ae00 !important;
  color: #43425d;
}

.d-comment-del-btn {
  margin-right: 10px;
}

.d-comment-actions {
  display: flex;
  justify-content: flex-end;
  button {
    height: 38px;
  }
}

.css-1fhf3k1-control {
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
  .css-107lb6w-singleValue {
    color: #495057;
  }
}

.page-title-breadcrumb {
  color: #43425d;
  margin-right: 1rem;
  margin-bottom: 5px;
}

.note-editor.note-frame
  .note-editing-area
  .note-editable[contenteditable="false"],
.note-editor.note-airframe
  .note-editing-area
  .note-editable[contenteditable="false"] {
  background-color: #e9ecef !important;
}

.picker-open {
  z-index: 2000;
  & > div {
    z-index: 2000;
  }
}

.admin-container {
  .app-main__inner {
    padding: 0 15px;
  }
}

.d-results-panel .d-results-panel-toggle {
  font-size: 18px !important;
  font-weight: 600;
}

.sim-layout-wrapper {
  background: #dbdbdb !important;
}

.cfc-info-text iframe,
.cfc-info-text video,
.cfc-info-text img {
  width: 100% !important;
}

@media screen and (min-width: 1001px) {
  .app-layout-main {
    padding: 0 32px;
  }

  .app-main__layout {
    width: 100%;
    // max-width: 1304px;
    background-color: rgba(235, 237, 237, 0.4);
    border-radius: 33px;
    padding: 43px 0;
    position: relative;
  }
}
