// Responsive Applications

@include media-breakpoint-down(md) {
  .app-inner-layout__sidebar {
    display: none !important;
  }

  .mobile-app-menu-btn {
    display: block;
  }

  .open-mobile-menu {

    .app-inner-layout__sidebar {
      display: block !important;
    }
  }
}

@media (min-width: 1001px) {
  .document-page {
    padding-top: 150px;
  }
  .documents-fixed-header {
    position: fixed;
    top: 0;
    left: 240px;
    /* height: 100px; */
    width: calc(100% - 240px);
    margin-top: 60px;
    padding: 0 0 0 15px;
    z-index: 10000;
    background: #fff;
    border-bottom: 1px solid rgba(13, 13, 18, 0.125);
  }
  .documents-fixed-header .app-page-title {
    margin-top: 0px !important;
  }
}