// BOOTSTRAP 4 VARIABLES

@import 'components/bootstrap4/functions';
@import 'components/bootstrap4/variables';
@import 'components/bootstrap4/mixins';

// Linear Icons
$linearicons-font-path: 'components/icons/linearicons';
@font-face {
  font-family: 'Linearicons-Free';
  src: url('#{$linearicons-font-path}/Linearicons-Free.eot?w118d');
  src: url('#{$linearicons-font-path}/Linearicons-Free.eot?#iefixw118d') format('embedded-opentype'), url('#{$linearicons-font-path}/Linearicons-Free.woff2?w118d') format('woff2'), url('#{$linearicons-font-path}/Linearicons-Free.woff?w118d') format('woff'),
    url('#{$linearicons-font-path}/Linearicons-Free.ttf?w118d') format('truetype'), url('#{$linearicons-font-path}/Linearicons-Free.svg?w118d#Linearicons-Free') format('svg');
  font-weight: normal;
  font-style: normal;
}
@import 'components/icons/linearicons';

$pe-7s-font-path: '~pe7-icon/dist/fonts';
@import '~pe7-icon/dist/scss/pe-icon-7-stroke.scss';

// ArchitectUI Themes Variables
@import 'themes/layout-variables';

// BOOTSTRAP 4 IMPORTS

@import 'components/bootstrap4/root';
@import 'components/bootstrap4/reboot';
@import 'components/bootstrap4/type';
@import 'components/bootstrap4/images';
@import 'components/bootstrap4/code';
@import 'components/bootstrap4/grid';
@import 'components/bootstrap4/tables';
@import 'components/bootstrap4/forms';
@import 'components/bootstrap4/buttons';
@import 'components/bootstrap4/transitions';
@import 'components/bootstrap4/dropdown';
@import 'components/bootstrap4/button-group';
@import 'components/bootstrap4/input-group';
@import 'components/bootstrap4/custom-forms';
@import 'components/bootstrap4/nav';
@import 'components/bootstrap4/navbar';
@import 'components/bootstrap4/card';
@import 'components/bootstrap4/breadcrumb';
@import 'components/bootstrap4/pagination';
@import 'components/bootstrap4/badge';
@import 'components/bootstrap4/jumbotron';
@import 'components/bootstrap4/alert';
@import 'components/bootstrap4/progress';
@import 'components/bootstrap4/media';
@import 'components/bootstrap4/list-group';
@import 'components/bootstrap4/close';
@import 'components/bootstrap4/modal';
@import 'components/bootstrap4/tooltip';
@import 'components/bootstrap4/popover';
@import 'components/bootstrap4/carousel';
@import 'components/bootstrap4/spinners';
@import 'components/bootstrap4/utilities';
@import 'components/bootstrap4/print';
//Bootstrap 5
@import '~bootstrap/scss/bootstrap';
// LAYOUT

@import 'layout/layout';

// UTILS

@import 'utils/helpers';
@import 'utils/backgrounds';
@import 'utils/animate';
@import 'utils/comps-animations';
@import 'utils/darktheme';
@import 'utils/common';

// ELEMENTS

@import 'elements/buttons';
@import 'elements/dropdown';
@import 'elements/navs';
@import 'elements/badges';
@import 'elements/cards';
@import 'elements/tabs';
@import 'elements/accordions';
@import 'elements/modals';
@import 'elements/loaders';
@import 'elements/progressbar';
@import 'elements/timeline';
@import 'elements/listgroup';
@import 'elements/forms';
@import 'elements/pagination';
@import 'elements/chat';

// DASHBOARD BOXES

@import 'widgets/chart-boxes/chart-boxes';
@import 'widgets/content-boxes/content-boxes';
@import 'widgets/profile-boxes/profile-boxes';

// PAGES

@import 'pages/userpages';
@import 'pages/dashboard';
@import 'pages/login';
@import 'pages/scores';
@import 'pages/activity';

// Applications

@import 'applications/applications-base';

// COMPONENTS

// Perfect Scrollbar
@import 'components/perfect-scrollbar/perfect-scrollbar';

// RC Tabs
@import 'components/rc-tabs/rc-tabs';

// Loading Buttons
@import 'components/loading-buttons/loading-buttons';

// Hamburger Button
@import 'components/hamburgers/hamburgers';

// Drawer
@import 'components/drawer/drawer';

// Notifications
@import 'components/notifications/sweetalerts';
// @import 'components/notifications/toastify';

// Modals
@import 'components/modals/rodal';

// Progress Bar
@import 'components/progress-bar/progress-bar';

// Slick Carousel
@import 'components/slick-carousel/slick/slick';

// Block loading
@import 'components/block-loading/block-loading';

// Popovers & Tooltips
@import 'components/popovers-tooltips/popovers-tooltips';

// Sticky Elements
@import 'components/sticky-elements/sticky-elements';

// Calendar
@import 'components/calendar/calendar';

// Splitters
@import 'components/splitters/splitters';

// Tables
@import 'components/tables/tables';

// Dropzone
@import 'components/forms/dropzone/dropzone';
@import 'components/forms/datepicker/datepicker';
