// Sidebar Base
// .admin-container {
//   .d-sidebar {
//     top: 15px;
//   }
// }
.d-sidebar {
  position: fixed;
  min-width: 240px;
  width: 240px;
  height: 100vh;
  overflow: hidden;
  z-index: 900;
  color: #fff;
  background: #43425d;
  left: 0;
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
  .vertical-nav-menu .metismenu-container .metismenu-link.active {
    background: rgba(173, 172, 210, 0.47);
  }
}

.d-sidebar-base {
  background: #43425d;
  min-width: 240px;
  width: 240px;
  min-height: 100vh;
}

.d-menu-logo {
  width: 190px;
  position: absolute;
  right: 25px;
  top: 30px;
}

.d-sidebar-nav-main {
  margin-top: 20px;
}

.d-sidebar-sub-nav {
  padding-left: 15px;
  text-align: right;
}

.d-nav-active {
  position: absolute;
  left: 0;
  top: 31px;
}

.d-sidebar-title {
  margin-bottom: 20px;
}

.d-sidebar-nav-main,
.d-sidebar-sub-nav {
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  & > img {
    margin-right: 10px;
  }
}

.d-sidebar-content {
  position: absolute;
  top: 130px;
  left: -10px;
  width: 100%;
}

.app-sidebar__heading {
  text-transform: none !important;
  font-size: $font-size-xs;
  margin: ($layout-spacer-x / 2) 0;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  position: relative;
}

.sidebar-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: 0.6;
  left: 0;
  top: 0;
  z-index: 12;
}

.d-logo {
  position: absolute;
  width: 200%;
  left: -94%;
  top: -330px;
}

.d-sidebar-nav-main img:not(.d-nav-active),
.d-sidebar-sub-nav img:not(.d-lg-icon) {
  max-width: 30px;
}

.d-sidebar a {
  background: rgba(67, 66, 93, 0.59);
}

.nav-i {
  background-color: #43425d;
  width: 100%;
  display: flex;
  cursor: pointer;
}

.nav-i-iw {
  width: 66px;
  height: 53px;
  background-color: rgb(60, 59, 83);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav-i-img {
}

.nav-i-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

%nav-i-title {
  font-size: 13px;
  font-family: 'Montserrat-Bold';
  color: #a5a4bf;
  display: flex;
  align-items: center;
  padding-left: 14px;
}

.nav-i-title {
  @extend %nav-i-title;
}

.nav-i-title__active {
  @extend %nav-i-title;
  color: #f9b000;
}

@media (max-height: 850px) {
  .d-sidebar-content {
    top: 140px;
  }
}

@media (max-width: 1500px) {
  .d-sidebar {
    min-width: 240px;
    width: 240px;
  }

  .d-sidebar-base {
    min-width: 240px;
    width: 240px;
  }

  .d-content {
    max-width: calc(100% - 240px);
  }
}

@media (max-width: 1000px) {
  .d-sidebar,
  .d-sidebar-base {
    display: none;
  }
  .d-content {
    max-width: 100%;
  }
}

.sidebar-wrapper {
  width: 252px;
  min-height: calc(100vh - 40px);
}
@media (max-width: 1200px) {
  .sidebar-wrapper {
    width: unset;
    height: unset;
    position: absolute;
    z-index: 9000;
  }
  .sidebar-logo {
    display: none;
  }
}
//
// // Sidebar Components
//
@import 'modifiers/navmenu-vertical';
//
// // Sidebar Modifiers
//
// @import "themes/sidebar-light";
// @import "themes/sidebar-dark";
// //@import "themes/sidebar-inverted";
//
//
// @import "modifiers/fixed-sidebar";
// @import "modifiers/closed-sidebar";
