// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url("~assets/utils/images/logo.png");
}

.app-logo-mobile {
  background: url("~assets/utils/images/logo.png") no-repeat center;
  background-size: contain;
  width: 100%;
  margin: 20px auto 0;
  display: block;
  height: 90px;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.app-logo-inverse {
  height: 100px;
  width: 177px;
  background: url("~assets/utils/images/logo.png");
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

input {
  outline: none !important;
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: 0.6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}
