// Footer base

.app-footer {
  height: $app-header-height;
  background: #43425D!important;
  border-color: #43425D!important;

  .app-footer__inner {
    padding: 0 $layout-spacer-x 0 ($layout-spacer-x / 2);
    height: $app-header-height;
    transition: all .2s;

    display: flex;
    align-content: center;
    align-items: center;

    .app-footer-left {
      display: flex;
      align-items: center;
    }

    .app-footer-right {
      margin-left: auto;
      display: flex;
    }
  }
}

// Footer Dots Section

.footer-dots {
  display: flex;
  align-items: center;
  align-content: center;

  .dots-separator {
    height: 40px;
    margin: 0 ($layout-spacer-x / 2.5);
    width: 1px;
    background: $gray-200;
  }
}

.dot-btn-wrapper {
  padding: .5rem;
  position: relative;
  display: flex;
  opacity: .7;
  transition: opacity .2s;

  .badge-abs {
    right: 50%;

    &.badge-dot-sm {
      top: -2px;
      margin-right: -3px;
    }
  }

  .dot-btn-icon {
    font-size: 1.8rem;
  }

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
}

// Footer Modifiers

@import "fixed-footer";

@media (max-width: 767px) {
  .app-footer__inner>div {
    display: block!important;
    .app-footer-center {
      display: none;
    }
    div:not(.app-footer-center) {
      text-align: center;
      width: 100%;
      display: block!important;
    }
  }
  .app-footer,
  .app-footer__inner {
    height: unset!important;
    padding: 15px;
  }
}
