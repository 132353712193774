@font-face {
  font-family: 'Gotham Bold';
  src: url('../fonts/Gotham Bold.otf');
}
@font-face {
  font-family: 'Gotham Light';
  src: url('../fonts/Gotham Light.otf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: 'Montserrat-Light';
  src: url('../fonts/Montserrat-Light.ttf');
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../fonts/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/RobotoBold.ttf');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/RobotoLight.ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/RobotoMedium.ttf');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/RobotoRegular.ttf');
}
@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('../fonts/RobotoCondensed-Regular.ttf');
}
@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url('../fonts/RobotoCondensed-Bold.ttf');
}
@font-face {
  font-family: 'CenturyGothic-Bold';
  src: url('../fonts/CenturyGothic-Bold.ttf');
}

.badge-complete {
  background: #f9b000;
  color: #43425d;
}
.d-header.admin-header .d-header-title {
  display: none;
}
.d-header-pdf {
  display: none;
}
.__react_component_tooltip.type-dark {
  background-color: #43425c !important;
}
.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #43425c !important;
}
.app-page-title {
  margin-top: -85px !important;
}
.form-control:focus {
  border-color: #f9b000 !important;
  box-shadow: 0 0 0 0.2rem rgba(249, 179, 0, 0.25);
}
.app-theme-gray .main-card > .card-body > .card-title::before,
.card-body > .card-title::before {
  background: #f9b000 !important;
}
.d-user-text {
  margin-right: 15px;
  font-size: 17px;
}
.app-footer-center span {
  padding: 0 5px;
}

.d-page-title-buttons {
  display: flex;
  flex-direction: row !important;
  button:not(:last-child) {
    margin-right: 15px;
  }
}
.css-9gakcf-option {
  background-color: #f9b000 !important;
}
.css-1pahdxg-control {
  border-color: #f9b000 !important;
  box-shadow: 0 0 0 1px #f9b000 !important;
}

.d-result {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.d-result-answer {
  text-align: right;
}
.d-swatch {
  width: 20px;
  height: 20px;
  border: 1px solid #71737b;
}

.modal-body-lg {
  z-index: 20000 !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(67, 66, 93, 0.15) !important;

  & a {
    color: #495057;
  }
}

.chrome-picker {
  width: 100% !important;
}
.dropdown-menu {
  z-index: 10000;
}

.d-lg-icon {
  max-width: 60px;
}

.page-title-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.d-btn-discard {
  margin-left: 15px;
}

.d-menu-actions-link > button {
  margin-top: 15px;
}

.d-actions-link {
  color: #f9b001;
  text-decoration: none !important;
  cursor: pointer;
  &:hover,
  &:active {
    color: #495057 !important;
  }
}

.text-overflow-breadcrumbs {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d-actions-link-span {
  color: #f9b001;
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    color: #0056b3;
  }
}

.d-table-filter {
  margin-bottom: 15px;
}

.d-table-filter-button {
  width: 100%;
  height: 100%;
}

.d-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  .block-ui {
    height: 100%;
  }
}

.d-checkbox-padding {
  padding-top: 25px;
}

.d-video-button {
  margin-top: 15px;
  margin-bottom: 15px;
}

.d-container {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 100vh;
  box-sizing: border-box;
  // overflow-x: hidden;
  font-family: 'Roboto', sans-serif;

  .app-page-title {
    position: relative;
    margin: unset;
    margin-bottom: 15px;
    padding: 0;
    padding-right: 16px;
  }
}

.d-content {
  width: 100%;
  padding-bottom: 100px;
  // position: relative;
  z-index: 100;
  background: #fff;
}
.d-content-padding {
  padding-right: 15px;
}
.d-results-toggle {
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
}
.d-result-category-title {
  font-size: 17px;
  margin-bottom: 0;
}
.d-result-category {
  margin-bottom: 25px;
}

.d-content-inner {
  min-height: 100vh;
}

.d-header {
  min-height: 100px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
}
.app-footer-left a {
  color: #fff !important;
  text-decoration: none !important;
  margin-right: 4px;
}
.d-footer {
  background: #ebeef3;
  min-height: 150px;
  padding-top: 20px;
  padding-bottom: 15px;
  color: #949b9d;
}
.app-footer__inner {
  padding-left: 280px !important;
}
.app-footer__inner > div {
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.app-footer-center {
  text-align: center;
  flex: 1;
}
.d-header-top {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 15px;
  color: #43425d;
  position: relative;
  z-index: 100;
  padding-right: 15px;
}
.d-tree-container {
  // height: 500px;
  width: 100%;
  // border: 1px solid #ccc;
  border-radius: 4px;
}

.rt-thead.-header {
  background: #f9b000;
}
.rt-th {
  border-bottom-color: #ced4da !important;
  border-right-color: #f9b000 !important;
}
.ReactTable {
  border-color: #ced4da !important;
}

.d-header-middle {
  display: flex;
  height: 70px;
  align-items: center;
  width: 100%;
}

.d-sidebar .vertical-nav-menu .metismenu-container .metismenu-link.active {
  background: transparent !important;
}
a.metismenu-link.active:after {
  content: '';
  background: #f9b000;
  width: 100px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 45px;
}
.d-about-icon {
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1 !important;
  background-image: url('~assets/utils/images/circle.svg');
}
.d-battery-icon {
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1 !important;
  background-image: url('~assets/utils/images/graph.svg');
}
.d-header-settings {
  margin-right: 70px;
  cursor: pointer;
  color: #ccc;
}
.d-header-print,
.d-switch {
  cursor: pointer;
  margin-right: 30px;
  .react-switch-bg {
    height: 20px !important;
    width: 48px !important;
    div:first-child,
    div:nth-child(2) {
      display: flex;
      justify-content: center;
      padding-top: 3px;
    }
  }
  .react-switch-handle {
    width: 18px !important;
    height: 18px !important;
  }
}

.d-switch-text {
  color: #a8a7a7;
  margin-top: 3px;
  margin-left: 5px;
}
.d-logout-text {
  cursor: pointer;
  color: #cccccc;
  font-family: 'Roboto-Regular';
  font-size: 17px;
  display: flex;
  align-items: center;
  img {
    height: 17px;
    margin-right: 6px;
  }
}

.d-header-middle-content,
.d-header-middle-content > div {
  text-align: center;
  margin: auto;
}

.d-header-bottom,
.d-switch {
  display: flex;
}

.d-tab {
  margin-right: 30px;
  font-family: 'Gotham Bold';
  font-size: 21px;
  color: #151f32;
  position: relative;
  &.d-tab-active > img {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.d-header-settings {
  color: #f9b001;
  span {
    color: #43425c;
  }
}
.d-header-title {
  line-height: 1;
  color: #43425d;
  font-family: 'Gotham Bold';
  font-size: 28px;
}

.d-header-subtitle {
  font-family: 'Gotham Light';
  font-size: 19px;
  color: #151f32;
}

.d-header-menu-mobile {
  display: none;
}
.d-logout-img {
  display: none;
  font-family: 'Roboto', sans-serif;
}

.d-mobile-menu-overlay {
  position: absolute;
  z-index: 20000;
  left: 0;
  width: 270px;
  min-height: 100vh;
  background: #43425d;
  transition: all 0.2s ease-out;
  margin-left: -100vw;
  &.open {
    margin-left: 0;
  }
}

.d-close-button {
  position: absolute;
  font-size: 20px;
  color: #fff;
  right: 15px;
  top: 5px;
}

.d-mobile-menu-item {
  display: flex;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #43425d;
  color: #fff !important;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: #43425d !important;
  }
  div {
    margin-right: 15px;
  }
}

.d-mobile-menu-overlay .metismenu-link {
  color: #fff !important;
  &.active,
  &:hover {
    color: #43425d !important;
    background: rgba(209, 243, 255, 0.25) !important;
  }
}

@media (max-width: 1200px) {
  // .d-header {
  //   padding-top: 30px;
  // }
  .d-header-title {
    font-size: 25px;
  }
  .d-header-menu-mobile {
    display: block;
    position: absolute;
    cursor: pointer;
    left: 15px;
    top: 10px;
    font-size: 20px;
  }
  .app-footer__inner {
    padding-left: 1.5rem !important;
  }
  .d-switch,
  .d-switch-text,
  .d-header-print {
    display: none;
  }
  // .d-logout-text {
  //   display: none;
  // }
  .d-header-top {
    padding-top: 10px;
  }
  .d-footer-content {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .d-row {
    display: block;
  }
  .d-content {
  }
  .d-total-score {
    border: 4px solid #f37b8a;
    margin-top: 15px;
  }
}

@media (max-width: 1000px) {
  .d-user-text {
    display: none;
  }

  .d-header-middle-content {
    padding-right: 100px;
    padding-left: 100px;
  }
  .d-header-settings {
    display: none;
  }
  .d-header-pdf {
    display: flex;
  }
  .d-logout-img {
    img {
      margin-right: 5px;
      height: 25px;
    }
    display: flex;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 10px;
    align-items: center;
  }
  .app-page-title {
    margin-top: 15px !important;
  }
  .d-footer-content {
    max-width: 100%;
    text-align: center;
  }
  .d-checkbox-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .d-logout-text {
    display: none !important;
  }
  .d-dropzone {
    .col-md-4 {
      padding-top: 15px;
    }
  }
  .d-header {
    min-height: 100px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
    padding-top: 30px;
    background-color: #f6f8f9;
    position: relative;
  }
}

@media (max-width: 500px) {
  .d-tab {
    font-size: 16px;
  }
}
