.d-footer-mobile,
.d-login-bg-mobile-small,
.d-login-logo-mobile,
.d-login-mobile-title {
  display: none
}
.d-login {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 768px){
    max-height: calc(99vh - 85px);
  }

  .d-login-input {
    border: 1px solid #F9B000;
  }

  .d-login-code {
    //margin: 0 8px;
    width: 40px!important;
    border: 1px solid #F9B000;
    border-radius: 4px;
    padding: 10px!important;
    height: 40px;
    display: block;
    text-align: center;
  }

  .d-login-bg {
    min-height: 100vh;
    background-size: cover;
    background-position: right;
    position: relative;
    background-color: #F4F4F4;
  }
  .d-login-bg-mobile {
    display: none;
  }
  &>div {
    width: 50%;
  }
  .d-login-image {
    position: absolute;
    bottom: -400px;
    width: 100%;
    max-width: 880px;
    object-fit: contain;
    left: -20px;
  }
  .form-control {
    padding: 1.5rem 0.75rem;
    padding-left: 40px;
  }
  .modal-content {
    min-height: 80vh;
    justify-content: center;
  }
  .d-login-form {
    max-width: 400px;
    margin: 0 auto;
  }
}
.d-login-form {
  margin-bottom: 30px;
}
.d-login-small-title {
  font-weight: 700;
  color: #43425D;
  font-size: 25px;
  opacity: 1!important;
  margin: 45px 0;
}
.d-input-icon-wrapper {
  position: absolute;
  height: calc(100% - 15px);
  display: flex;
  align-items: center;
  left: 18px;
  z-index: 100;
}
.d-forgot-pass-popup-bg {
  position: fixed;
  background: rgba(0,0,0,0.7);
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-forgot-pass-popup {
  background: #F9B000;
  position: relative;
  top: 20px;
  padding: 20px;
  border-radius: 20px;
  max-width: 400px;
  .d-forgot-pass-popup-title {
    color: #43425D;
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .d-forgot-pass-popup-content {
    color: #43425D;
    margin-bottom: 40px;
  }
  img {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.d-login-highlight {
  background: #f9b000;
  margin: 0 -16px;
  text-align: center;
  padding: 10px 0;
  p {
    margin: 0;
    color: #141421;
    font-size: 16px;
    font-weight: 700;
  }
  &.small {
    padding: 5px 0;
  }
}

.d-pass-input-wrapper {
  position: relative;
}
.d-login-title {
  color: #43425d;
  font-family: CenturyGothic-Bold, sans-serif;
  letter-spacing: 0.9px;
  font-size: 45px;
  font-weight: 700;
  opacity: 1!important;
  margin-top: 30px;
  &.small {
    font-size: 30px;
  }
}
.d-eye-btn {
  position: absolute;
  right: 15px;
  top: 0;
  cursor: pointer;
  color: #b5b5b5;
  height: calc(100% - 15px);
  display: flex;
  align-items: center;
}
.d-login-subtitle {
  font-size: 14px!important;
  max-width: 300px;
  font-weight: 400;
  margin: 0 auto 20px;
}
.d-login-button {
  display: block;
  background: #F9B000!important;
  border-color: #F9B000!important;
  width: 100%;
  max-width: 260px;
  font-size: 16px;
  padding: 13px;
  font-family: 'RobotoCondensed-Regular', sans-serif;
  font-weight: 300;
  letter-spacing: 1.6px;
  margin: 100px auto 0;
  border-radius: 35px;
  position: relative;
  box-shadow: 0 20px 50px #00000034;

  @media screen and (max-width: 768px){
    margin: 30px auto 0;

  }

  &:before {
    content: '';
    position: absolute;
    background: url("../utils/images/arrowright.svg") no-repeat;
    filter: brightness(500%);
    width: 16px;
    height: 16px;
    right: 12px;
    top: 52%;
    transform: translateY(-50%);
  }
}
.d-pass-forgot {
  font-size: 14px;
  display: block;
  width: max-content;
  margin-left: auto;
  cursor: pointer;
  color: #9C9D9D;
}
.d-footnote {
  padding: 60px 15px;
  text-align: center;
  span {
    text-decoration: underline;
    color: #43425d;
  }
  a {
    text-decoration: none!important;
  }
  span.d-login-link {
    font-weight: 700;
    color: #e5027c;
    text-decoration: none!important;
  }
}
.d-login-modal {
  input {
    color: #43425d;
  }
  .modal-content {
    border: none;
  }
}

@media (max-width: 1199px) {
  .d-footer-mobile {
    display: block;
  }
  .d-login {
    width: 100vw;
    min-height: 100vh;
    padding: 100px 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &>div {
      width: 100%;
    }
    .d-login-small-title {
      margin-top: 0;
    }
    .d-login-bg {
      display: none;
    }
    .d-login-bg-mobile {
      display: block;
      min-height: 50vh;
      position: absolute;
      background-size: cover;
      background-position: right;
      top: 0;
      left: 0;
    }
    .modal-content {
      min-height: unset;
    }
    .d-login-content {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding: 40px;
      background-color: #fff!important;
      z-index: 1000;
      border-radius: 10px;
      position: relative;
      -webkit-box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.38);
      -moz-box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.38);
      box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.38);
    }
    .d-login-form {
      margin-bottom: 30px;
    }
    .h-100 {
      height: unset!important;
      min-height: unset!important;
    }
  }
}

@media (max-width: 767px) {
  .d-login {
    .d-login-content {
      padding: 15px;
    }
    .d-login-title.small {
      font-size: 25px;
    }
  }
}

.d-login-logo {
  width: 100%;
  position: relative;
  max-width: 395px;
  height: 210px;
  z-index: 1;
  margin-top: 180px;
}

@media (max-width: 767px) {
  .d-login-desktop-title {
    display: none;
  }
  .d-login-mobile-title {
    display: block;
  }
  .d-login {
    display: block;
    padding-top: 15px;
    .d-login-bg,
    .d-login-bg-mobile {
      display: none;
    }

    .login-logo {
      width: 100%;
      max-width: 385px;
    }
    .d-login-content {
      margin-top: 30px;
      .app-logo-inverse {
        display: none;
      }
    }

    .d-login-bg-mobile-small {
      width: calc(100% + 30px);
    }
  }
  .d-login-logo-mobile {
    display: block;
    background-position: center;
  }
  .d-login-bg-mobile-small {
    display: block;
    min-height: 27vh;
    margin: 0 -15px;
    background-size: cover;
    background-position: right top;
    // top: 0;
    // left: 0;
  }
  .bg-pos-right {
    background-position: right!important;
  }
}

@media (max-width: 600px) {
  .d-forgot-pass-popup-bg {
    background: rgba(0,0,0,0.2);
    padding: 30px;
  }
}
