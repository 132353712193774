/* PULSE ANIMATION */
.pulse,
.pulse-lg {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.5;
  cursor: pointer;
  box-shadow: 0 0 0 10px rgba(255,255,255, 0.4);
  animation: none;
  margin: 0 auto;
  &.pulse-lg {
    width: 30px;
    height: 30px;
    box-shadow: 0 0 0 15px rgba(255,255,255, 0.4);
  }
}

.pulse-wrapper,
.pulse-lg-wrapper {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  &.pulse-lg-wrapper {
    bottom: 35px;
  }
}
.pulse:hover {
  animation: pulse 2s;
  opacity: 1;
}
.pulse-lg:hover {
  animation: pulse-lg 2s;
  opacity: 1;
}
.pulse-lg.selected,
.pulse.selected {
  opacity: 1;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255,255,255, 0);
      box-shadow: 0 0 0 10px rgba(255,255,255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
      box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}
@-webkit-keyframes pulse-lg {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
  }
  70% {
      -webkit-box-shadow: 0 0 0 20px rgba(255,255,255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}
@keyframes pulse-lg {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba(255,255,255, 0);
      box-shadow: 0 0 0 20px rgba(255,255,255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
      box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}

.d-score-page {
  max-width: 1170px;
  font-family: 'Roboto', sans-serif;
}
.app-main__inner .no-see {
  display: none;
}
.survey-img {
  padding: 15px 0;
  max-width: 1170px;
  img {
    max-width: 100%;
  }
}

/* SCORES */

.survey-goals {
  background: #ebeded;
  border-radius: 33px;
  padding: 19px 20px 35px;
  // min-height: 362px;
  margin-left: -15px;
}
.survey-notes {
  // max-width: 200px;
  color: #767374;
  border: 4px solid #EBEDED;
  padding: 15px;
  border-radius: 2px;
  margin-left: -15px;
}

.mini-lifestyle-score-bar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 140px;
  border: 1px solid #707070;
  border-radius: 0 5px 5px 5px;
  display: flex;
  align-items: flex-end;
  background: #fff;
}

.mini-lifestyle-bar__fill {
  background-color: #03a391;
  width: 100%;
  transition: all 1s ease-out;
}

.mini-lifestyle-score-text-wrapp {
  margin-left: 3px;
}

.mini-lifestyle-score__text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #707070;
  border: 1px solid #f9b000;
  border-radius: 9px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.mini-lifestyle-score__title {
  margin-left: 3px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-size: 12px;
  color: #767374;
}

.mini-score-bar {
  width: 14%;
  padding-right: 15px;
  max-width: 100px;
}


@media screen and (min-width: 1000px) {
  .mini-lifestyle-score__text {
    font-size: 16px;
  }
}

/* SCORES GENERAL PAGE */

.your-results {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.your-results-safe-area {
  background-color: #ebeded;
  border-radius: 33px;
  padding: 19px 20px 35px;
  display: flex;
  flex-direction: column;
}

.your-results .heading {
  text-align: center;
  font-size: 22px;
  font-family: 'Roboto', sans-serif;;
  color: #f9b000;
  margin-bottom: 13px;
}

.your-results .title,
.survey-goals .title {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #43425d;
  text-align: left;
}

.survey-goals-content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
}

.survey-goal {
  padding: 7px 15px;
  border-radius: 7px;
  border: 1px solid #707070;
  background-color: #fff;
  margin-bottom: 10px;
  text-align: center;
}

.your-results .score-wrapp {
  margin-top: 18px;
  display: flex;
}

.your-results .lifestyle-score-bar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 317px;
  border: 1px solid #707070;
  border-radius: 0 14px 14px 14px;
  display: flex;
  align-items: flex-end;
  margin-right: 14px;
  background: #fff;
}

.your-results .lifestyle-bar__fill {
  background-color: #03a391;
  width: 100%;
  transition: all 1s ease-out;
}

.your-results .score-wrapp-rs {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
}

.your-results .score-bar {
  width: 100%;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid #f9b000;
  height: 27px;
  min-width: 186px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;;
  color: #707070;
  margin-bottom: 15px;
  margin-top: 15px;
}

.your-results .score-wrapp-rs .score-text {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  color: #666769;
}

.gradient-score-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 22px;
  margin-bottom: 22px;
}

.gradient-bar {
  width: 100%;
  height: 35px;
  border-radius: 21px;
  background: linear-gradient(
    to right,
    #03a391,
    #a1be22,
    #ffe033,
    #ffe033,
    #ef3131,
    #f80202
  );
}

.gradient-bar-scale {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
}

.scale-text {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #767374;
}

.score-meaning-wrapp {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.score-meaning-wrapp .title {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #666769;
}

.score-meaning-wrapp .text {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #666769;
  line-height: 20px;
}

.greyline {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.your-results .greyline {
  margin-top: 30px;
  margin-bottom: 47px;
}

.your-results .organ-scores {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 25px;
}

.your-results .organ-scores-text {
  margin-top: 17px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #666769;
}

.score-row-mobile,
.score-row,
.score-colors-mobile,
.score-bar-mobile,
.title-mobile {
  display: none;
}

@media screen and (min-width: 600px) {
  .your-results .organ-scores {
    justify-content: flex-start;
  }
  .your-results-safe-area {
    align-self: center;
  }
}

@media screen and (min-width: 1000px) {

  .your-results .organ-scores {
    justify-content: space-between;
  }
  .your-results .heading {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .your-results .score-wrapp {
    margin-top: 27px;
  }
  .your-results .title {
    font-size: 20px;
  }
  .your-results .score-wrapp-rs .score-text {
    font-size: 14px;
  }
  .your-results .score-bar {
    height: 40px;
    border-radius: 10px;
    font-size: 22px;
  }
  .scale-text {
    font-size: 14px;
  }
  .gradient-score-bar {
    margin: 33px 0;
  }
  .score-meaning-wrapp .text {
    font-size: 14px;
    margin-top: 6px;
  }
  .your-results .organ-scores-text {
    font-size: 14px;
  }
}
@media (max-width: 1100px) {
  .score-row-desktop {
    display: none;
  }
  .score-row {
    display: block;
  }
  .score-row-mobile {
    display: block;
  }
  .lifestyle-score-bar {
    width: 90px!important;
    height: 145px!important;
    margin-right: unset!important;
    margin: 0 auto!important;
  }
  .your-results .score-bar {
    min-width: 120px;
  }
  .score-bar {
    font-size: 16px!important;
  }
  .survey-notes-text {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .survey-goals {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .survey-notes {
    margin-left: 0;
    margin-bottom: 15px;
  }
  .survey-notes-text {
    font-size: 12px;
    font-style: italic;
  }
  .survey-notes {
    border: none;
  }
  .score-colors-desktop {
    display: none;
  }
  .score-colors-mobile {
    display: block;
  }
  .title-mobile,
  .score-bar-mobile {
    display: block;
  }
  .title-mobile {
    text-align: center;
  }
  .title-desktop,
  .score-bar-desktop {
    display: none!important;
  }
  .score-bar-mobile {
    width: 145px;
  }
  .pulse,
  .pulse-lg {
    transform: scale(0.7)
  }
  .survey-goals {
    min-height: unset;
    .survey-goals-content {
      min-height: unset;
      margin-top: 15px;
    }
  }
}

@media (max-width: 700px) {
  .mini-lifestyle-score-bar {
    height: 120px;
  }
}

@media (max-width: 600px) {
  .mini-lifestyle-score-bar {
    height: 100px;
  }
}

@media (max-width: 450px) {
  .lifestyle-score-bar {
    width: 60px !important;
    height: 105px !important;
  }
  .mini-score-bar {
    width: 25%;
    margin-bottom: 15px;
  }
  .your-results .organ-scores {
    justify-content: flex-start;
  }
  .your-results .title {
    text-align: center;
  }
}
